import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';


const welcome_pack_services_swiper = new Swiper('.welcome-pack__main-content-wrapper__service-pages__grid__services-swiper', {
    modules: [Navigation, Pagination, Keyboard],
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 10,
    keyboard: {
        enabled: true,
    },
    pagination: {
        el: ".swiper-pagination-services-welcome-pack",
        clickable: true,
    },
});


const welcome_pack_articles_swiper = new Swiper('.welcome-pack__main-content-wrapper__relevant-content-block__grid__relevant-content-swiper', {
    modules: [Navigation, Pagination, Keyboard],
    slidesPerView: 1.5,
    centeredSlides: true,
    spaceBetween: 30,
    keyboard: {
        enabled: true,
    },
    pagination: {
        el: ".swiper-pagination-relevant-content-welcome-pack",
        clickable: true,
    },
});

