
document.addEventListener('DOMContentLoaded', async function (e) {
    const selfServeInfo = await getSelfServeInfo(1);
    await initWidget(selfServeInfo);
    rotationIconAnimation();
});

/**
 * Main function for retrieving the info for each question in the widget
 *
 * @param question
 * @param userType
 * @param userRole
 * @returns {Promise<any>}
 */
async function getSelfServeInfo(question, userType = null, userRole = null) {
    let id = null, matches = document.body.className.match(/(^|\s)page-id-(\d+)(\s|$)/);
    if (matches) {
        id = parseInt(matches[2]);
    }
    let questionId = `question_${question}`
    return (await fetch(`/wp-json/aura-self-serve/v1/getInfo?page-id=${id}&question_id=${questionId}&user_type=${userType}&user_role=${userRole}`)).json();
}

/**
 * Initialises the widget
 *
 * @param info
 * @returns {Promise<boolean>}
 */
async function initWidget(info) {
    const selfServeWidgetContainer = document.querySelector('.self-serve-widget__wrapper');
    const selfServeWidgetLogo = selfServeWidgetContainer.querySelector('.self-serve-widget__logo');
    const selfServeContentWrapper = selfServeWidgetContainer.querySelector('.self-serve-widget__wrapper-content');
    const selfServeAssistant = selfServeWidgetContainer.querySelector('.self-serve-widget__assistant');
    const selfServeAssistantClose = selfServeAssistant.querySelector('.self-serve-widget__assistant-close');
    const selfServeAssistantMessagesWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-wrapper');
    const selfServeAssistantAnswersWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-answers-wrapper');
    const selfServeAssistantReset = selfServeAssistant.querySelector('.self-serve-widget__assistant-reset');
    const selfServeAssistantGreetingMessageWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant__greeting-message-wrapper');
    const selfServeAssistantMessageAnswerWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-answers-wrapper');
    const selfServeAssistantResultWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant__result-wrapper');
    const selfServeAssistantIcon = document.querySelector('.self-serve-widget__assistant__logo');
    const selfServeAssistantSpan = document.querySelector('.semi');
    const currentQuestion = JSON.parse(localStorage.getItem('current_question'));


    const {show_on_page, question, answers, greeting_message} = info;

    //don't show on page if listed on exclude list
    if (!show_on_page) {
        return false;
    }
    //displaying widget icon on page
    selfServeWidgetContainer.style.display = 'flex';

    //showing initial information when hovering over icon
    selfServeAssistantIcon.addEventListener('mouseover', function (e){
        selfServeContentWrapper.classList.add('active');
    });
    //close initial information when leaving container of content
    selfServeWidgetContainer.addEventListener('mouseleave', function (e){
        selfServeContentWrapper.classList.remove('active');
    });

    //When clicking on content wrapper show assistant with messages and hide the content
    selfServeContentWrapper.addEventListener('click', async function (e){
        // show the assistant and hide the contentWrapper
        selfServeContentWrapper.classList.add('d-none');
        selfServeAssistant.classList.remove('d-none');

        if (!currentQuestion) {
            //if we have no current question in local storage, this will run

            //create get started button as we need to attach click event listener to it
            const gettingStartedBtn = document.createElement('button');
            gettingStartedBtn.classList.add('self-serve-widget__assistant__greeting-message-wrapper__button','d-block','btn-primary');
            gettingStartedBtn.innerHTML = 'Get started';
            gettingStartedBtn.addEventListener('click', (e) => {
                //hide the greeting message wrapper
                selfServeAssistantGreetingMessageWrapper.classList.remove('active');
                //show the messages and answers wrapper
                selfServeAssistantMessageAnswerWrapper.classList.add('active');
                //reset messages and answers wrapper
                selfServeAssistantMessagesWrapper.innerHTML = '';
                selfServeAssistantAnswersWrapper.innerHTML = '';
                updateMessageOrAnswers(selfServeAssistantMessagesWrapper, question.question, null,true);
                //set question in localStorage for later use
                localStorage.setItem('current_question', JSON.stringify(question));
            });

                await updateMessageOrAnswers(selfServeAssistantGreetingMessageWrapper, 'Hi there!', true);
                await updateMessageOrAnswers(selfServeAssistantGreetingMessageWrapper, greeting_message, 1000, true);
                await updateMessageOrAnswers(selfServeAssistantGreetingMessageWrapper, gettingStartedBtn, 2100, true);
        } else {
            //This will run if we have a current question saved in local storage

            //previous and current answer vars
            let previousQuestion, currentAnswer;
            //get current question saved in local storage
            const currentQuestion = JSON.parse(localStorage.getItem('current_question'));

            //hide the greeting message wrapper
            selfServeAssistantGreetingMessageWrapper.classList.remove('active');
            //show the messages and answers wrapper
            selfServeAssistantMessageAnswerWrapper.classList.add('active');
            //reset messages and answers wrapper
            selfServeAssistantMessagesWrapper.innerHTML = '';
            selfServeAssistantAnswersWrapper.innerHTML = '';
            //get current question
            const currentQuestionAsNumber = getNextOrPreviousQuestion(currentQuestion.question_id, 'current');
            if (currentQuestionAsNumber > 1) {
                //get previous question
                previousQuestion = getNextOrPreviousQuestion(currentQuestion.question_id, 'previous');
                //get answer from previous question which is needed to load answers for current question
                currentAnswer = JSON.parse(localStorage.getItem('question_' + previousQuestion));
            }
            if (currentQuestionAsNumber >= 2){
                //display back to start button
                setTimeout(() => {
                    selfServeAssistantReset.classList.remove('d-none');
                }, 3500)
            }

            if (currentQuestionAsNumber === 3) {
                //question 1 answer needed to load question 3 answer set
                currentAnswer.role = JSON.parse(localStorage.getItem('question_1')).answer;
            }
            //get current question information
            showLoading(selfServeAssistantAnswersWrapper);
            const response = await getSelfServeInfoForQuestion(currentQuestion.question_id, currentQuestionAsNumber, currentAnswer)
            showLoading(selfServeAssistantAnswersWrapper, false);

            if (currentAnswer && currentAnswer.answer === 'Something else') {
                await updateMessageOrAnswers(selfServeAssistantAnswersWrapper, response.answers, null);
            }else{
                //update question wrapper
                getAnswerMessages().forEach( (message) => {
                    updateMessageOrAnswers(selfServeAssistantMessagesWrapper, message, null, true);
                    addEventListenersToAnswerMessages();
                });
                await updateMessageOrAnswers(selfServeAssistantMessagesWrapper, response.question.question, null, true);
            }
        }

    });

    //start rotating icon
    rotationIconAnimation();

    // close widget and show icon
    selfServeAssistantClose.addEventListener('click', () => {
       closeWidget();
    });

    //bind escape key to trigger closing widget and enter key to open widget for accessibility.
    document.addEventListener('keydown', async (e) => {
        if (e.key === "Escape") {
            selfServeAssistant.classList.add('d-none');
            selfServeContentWrapper.classList.remove('d-none');
            //hide result wrapper
            selfServeAssistantResultWrapper.classList.remove('active');
            //show greeting message wrapper
            selfServeAssistantGreetingMessageWrapper.classList.add('active');
            //Hide messages and answers wrapper
            selfServeAssistantMessageAnswerWrapper.classList.remove('active');
            //Hide back to start button
            selfServeAssistantReset.classList.add('d-none');
        }
        if(selfServeWidgetContainer === document.activeElement && e.key === 'Enter') {
            // show the assistant and hide the contentWrapper
            selfServeContentWrapper.classList.add('d-none');
            selfServeAssistant.classList.remove('d-none');

            if (!currentQuestion) {
                //if we have no current question in local storage, this will run

                //create get started button as we need to attach click event listener to it
                const gettingStartedBtn = document.createElement('button');
                gettingStartedBtn.classList.add('self-serve-widget__assistant__greeting-message-wrapper__button','d-block', 'btn', 'btn-primary');
                gettingStartedBtn.innerHTML = 'Get started';
                gettingStartedBtn.addEventListener('click', (e) => {
                    //hide the greeting message wrapper
                    selfServeAssistantGreetingMessageWrapper.classList.remove('active');
                    //show the messages and answers wrapper
                    selfServeAssistantMessageAnswerWrapper.classList.add('active');
                    //reset messages and answers wrapper
                    selfServeAssistantMessagesWrapper.innerHTML = '';
                    selfServeAssistantAnswersWrapper.innerHTML = '';
                    updateMessageOrAnswers(selfServeAssistantMessagesWrapper, question.question, null,true);
                    updateMessageOrAnswers(selfServeAssistantAnswersWrapper, answers, 4500, true);
                    //set question in localStorage for later use
                    localStorage.setItem('current_question', JSON.stringify(question));
                });
                await updateMessageOrAnswers(selfServeAssistantGreetingMessageWrapper, 'Hi there!', true);
                await updateMessageOrAnswers(selfServeAssistantGreetingMessageWrapper, greeting_message, 2000, true);
                await updateMessageOrAnswers(selfServeAssistantGreetingMessageWrapper, gettingStartedBtn, 4500, true);
            } else {
                //This will run if we have a current question saved in local storage

                //previous and current answer vars
                let previousQuestion, currentAnswer;
                //get current question saved in local storage
                const currentQuestion = JSON.parse(localStorage.getItem('current_question'));

                //hide the greeting message wrapper
                selfServeAssistantGreetingMessageWrapper.classList.remove('active');
                //show the messages and answers wrapper
                selfServeAssistantMessageAnswerWrapper.classList.add('active');
                //reset messages and answers wrapper
                selfServeAssistantMessagesWrapper.innerHTML = '';
                selfServeAssistantAnswersWrapper.innerHTML = '';
                //get current question
                const currentQuestionAsNumber = getNextOrPreviousQuestion(currentQuestion.question_id, 'current');
                if (currentQuestionAsNumber > 1) {
                    //get previous question
                    previousQuestion = getNextOrPreviousQuestion(currentQuestion.question_id, 'previous');
                    //get answer from previous question which is needed to load answers for current question
                    currentAnswer = JSON.parse(localStorage.getItem('question_' + previousQuestion));
                }
                if (currentQuestionAsNumber >= 2){
                    //display back to start button
                    setTimeout(() => {
                        selfServeAssistantReset.classList.remove('d-none');
                    }, 3500)
                }

                if (currentQuestionAsNumber === 3) {
                    //question 1 answer needed to load question 3 answer set
                    currentAnswer.role = JSON.parse(localStorage.getItem('question_1')).answer;
                }
                //get current question information
                const response = await getSelfServeInfoForQuestion(currentQuestion.question_id, currentQuestionAsNumber, currentAnswer)
                if (currentAnswer && currentAnswer.answer === 'Something else') {
                    await updateMessageOrAnswers(selfServeAssistantAnswersWrapper, response.answers);
                }else{
                    //update question wrapper
                    if (getAnswerMessages().length >  0) {
                        getAnswerMessages().forEach( (message) => {
                            updateMessageOrAnswers(selfServeAssistantMessagesWrapper, message, null, true);
                        });
                        addEventListenersToAnswerMessages();
                    }
                    await updateMessageOrAnswers(selfServeAssistantMessagesWrapper, response.question.question, null ,true);
                }
            }
        }
    });

    //back to start widget action
    selfServeAssistantReset.addEventListener('click', (e) => {
        onReset();
    });

}

function getAnswerMessages()
{
    let answerMessages = [];
    for (let i = 0; i <= 3; i++) {
        let question = `question_${i}`
        if (localStorage.getItem(question)){
            answerMessages.push(getAnswerMessage(question));
        }
    }

    return answerMessages;
}

function addEventListenersToAnswerMessages()
{
    const answerMessages = document.querySelectorAll('.self-serve-widget__assistant-messages-wrapper__answer-message');

    Array.from(answerMessages).forEach((message) => {
        message.addEventListener('click', function (e){
            const questionId = e.target.getAttribute('data-value');
            onPickAnswer(e, questionId);
            removeLocalStorageAnswerLog(questionId);
        });
    });
}

function getAnswerMessage(question_id)
{
    //get value that has been selected
    const value = JSON.parse(localStorage.getItem(question_id));
    //create message dependant on question
    let previousAnswerString;
    switch (question_id) {
        case 'question_1':
            previousAnswerString = `<p class="self-serve-widget__assistant-messages-wrapper__answer-message" data-value="question_1"> A ${value.answer}, Great.</p>`
            break;
        case 'question_2':
            previousAnswerString = `<p class="self-serve-widget__assistant-messages-wrapper__answer-message" data-value="question_2">You're in ${value.answer}</p>`
            break;
    }

    return previousAnswerString;
}

function closeWidget()
{
    const selfServeWidgetContainer = document.querySelector('.self-serve-widget__wrapper');
    const selfServeAssistant = selfServeWidgetContainer.querySelector('.self-serve-widget__assistant');
    const selfServeAssistantGreetingMessageWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant__greeting-message-wrapper');
    const selfServeAssistantMessageAnswerWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-answers-wrapper');
    const selfServeAssistantResultWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant__result-wrapper');
    const selfServeContentWrapper = selfServeWidgetContainer.querySelector('.self-serve-widget__wrapper-content');
    selfServeAssistant.classList.add('d-none');
    selfServeContentWrapper.classList.remove('d-none');
    //hide result wrapper
    selfServeAssistantResultWrapper.classList.remove('active');
    //show greeting message wrapper
    selfServeAssistantGreetingMessageWrapper.classList.add('active');
    //Hide messages and answers wrapper
    selfServeAssistantMessageAnswerWrapper.classList.remove('active');
}


/**
 * Rotate icon element at random intervals between 15 and 50 seconds
 */
function rotationIconAnimation() {
    const selfServeAssistantIcon = document.querySelector('.self-serve-widget__logo__animated');

    if (!selfServeAssistantIcon) return;

    function startRotation() {
        selfServeAssistantIcon.classList.add('rotating');
        setTimeout(() => {
            selfServeAssistantIcon.classList.remove('rotating');
            scheduleNextRotation();
        }, 1500);
    }

    function scheduleNextRotation() {
        const randomDelay = Math.floor(Math.random() * (50000 - 15000 + 1)) + 15000; // Random delay between 15s and 50s
        setTimeout(startRotation, randomDelay);
    }

    startRotation();
}

/**
 * attach click event to answer element
 */
function attachAnswersEventListener() {
 const answers = document.querySelectorAll('.self-serve-widget__assistant__answer');

 if (answers.length > 0) {
     answers.forEach(answer => {
         if (answer.parentNode.nodeName.toLowerCase() !== 'a') {
             answer.addEventListener('click', (e) => {
                 onPickAnswer(e);
             })
         }
     })
 }
}


function onSelectAnswer()
{
    const answerSelect = document.querySelector('.self-serve-widget__assistant-messages-wrapper__select');

        answerSelect?.addEventListener('change', function (e){
            onPickAnswer(e);
        });
}

/**
 * Action when picking an answer
 *
 * @param e
 * @returns {Promise<void>}
 */
async function onPickAnswer(e, questionId = null) {
    const selfServeWidgetContainer = document.querySelector('.self-serve-widget__wrapper');
    const selfServeAssistant = selfServeWidgetContainer.querySelector('.self-serve-widget__assistant');
    const selfServeAssistantMessagesWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-wrapper');
    const selfServeAssistantAnswersWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-answers-wrapper');
    const selfServeAssistantReset = selfServeAssistant.querySelector('.self-serve-widget__assistant-reset');
    const selfServeAssistantResultWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant__result-wrapper');
    const selfServeAssistantMessageAnswerWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-answers-wrapper');
    const selfServeAssistantResultLink = selfServeAssistant.querySelector('.self-serve-widget__assistant__result-wrapper__link');

    //get value of answer being picked
    let answerVal = null;
    if (e.target.nodeName === "SELECT") {
        answerVal = e.target.value;
    } else {
        answerVal = e.target.getAttribute('data-value');
    }

    //check if answer inludes a forward slash, this means the value is a link. Navigate to this link within the site
    if (e.target.nodeName === "a") {
        // Get the current protocol (e.g., "http:" or "https:")
        const protocol = window.location.protocol;

        // Get the current host (e.g., "www.example.com")
        const host = window.location.host;

        // Define the URL path you want to append
        const urlPath = answerVal;

        // Construct the full URL
        const fullUrl = `${protocol}//${host}${urlPath}`;

        // Navigate to the full URL
        window.location.href = fullUrl;

        closeWidget();
    }
    const currentQuestion = JSON.parse(localStorage.getItem('current_question'));
    const questionAnswer = {
        question: currentQuestion.question_id,
        answer: answerVal,
    };
    //save answer and question to local storage if we haven't passed a question id
    if (!questionId) {
        localStorage.setItem(currentQuestion.question_id, JSON.stringify(questionAnswer));
    }
    //increment/decrement questionId
    let nextOrPrevQuestion;
    if (questionId) {
         nextOrPrevQuestion = getNextOrPreviousQuestion(currentQuestion.question_id, 'previous');
    } else {
         nextOrPrevQuestion = getNextOrPreviousQuestion(currentQuestion.question_id, 'next');
    }
    //get current question answer from local storage
    let currentAnswer;
    if (questionId) {
        let nextOrPrevQuestionId = questionId === 'question_1' ? 1 : nextOrPrevQuestion - 1;
        currentAnswer = JSON.parse(localStorage.getItem(`question_${nextOrPrevQuestionId}`));

    } else {
        currentAnswer = JSON.parse(localStorage.getItem(currentQuestion.question_id));
    }
    //get the question
    if (nextOrPrevQuestion >= 2){
        //display back to start button
        setTimeout(() => {
            selfServeAssistantReset.classList.remove('d-none');
        }, 3500)
    }

    if (nextOrPrevQuestion === 3) {
        currentAnswer.role = JSON.parse(localStorage.getItem('question_1')).answer;
    }
    //if last question
    if (nextOrPrevQuestion > 3) {
        showAndHideLoading('show');
        //collect all questions/answers from localstorage
        const questionOne = JSON.parse(localStorage.getItem('question_1'));
        const questionTwo = JSON.parse(localStorage.getItem('question_2'));
        const questionThree = JSON.parse(localStorage.getItem('question_3'));
        //get tags associated with packaging challenge
        const tagsResponse = (await fetch(`/wp-json/aura-self-serve/v1/getChallengeTags?packaging_challenge=${questionThree.answer}`)).json();
        const tags = await tagsResponse;
        //hide the messages-answer wrapper
        selfServeAssistantMessageAnswerWrapper.classList.remove('active');
        showAndHideLoading(null, null, 2500);
        //show result wrapper
        setTimeout((e) => {
            selfServeAssistantResultWrapper.classList.add('active');
        }, 1000);
        //generate link for user personalised page
        selfServeAssistantResultLink.href = `/welcome-pack?org=${questionOne.answer}&role=${questionTwo.answer}&packaging_challenge=${questionThree.answer}&${tags.tags}`

        const downloadButton =  selfServeAssistant.querySelector('.self-serve-widget__assistant__result-wrapper__download');
        downloadButton.addEventListener('click', function (e) {
            printURL(selfServeAssistantResultLink.href)
        });
        const shareButton = selfServeAssistant.querySelector('.self-serve__share-btn');
        if(shareButton) {
            const emailSubject = "Check out this page";
            const emailBody = `I found this page useful: ${selfServeAssistantResultLink.href}`;
            const mailtoLink = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
            shareButton.addEventListener('click', function (e) {
                window.location.href = mailtoLink;
            });
        }
    }else{
        //ping off to backend
        showLoading(selfServeAssistantAnswersWrapper);
        let response;
        if (questionId) {
            response = await getSelfServeInfoForQuestion(currentQuestion.question_id, questionIdAsNumber(questionId), currentAnswer)
        } else {
            response = await getSelfServeInfoForQuestion(currentQuestion.question_id, nextOrPrevQuestion, currentAnswer)
        }
        showLoading(selfServeAssistantAnswersWrapper, false);
        //update current question in local storage
        localStorage.setItem('current_question', JSON.stringify(response.question));
        if (currentAnswer.answer === 'Something else') {
            await updateMessageOrAnswers(selfServeAssistantAnswersWrapper, response.answers, null);
        }else{
            if (getAnswerMessages().length >  0) {
                getAnswerMessages().forEach((message) => {
                    updateMessageOrAnswers(selfServeAssistantMessagesWrapper, message, null, true);
                });
            }
            await updateMessageOrAnswers(selfServeAssistantMessagesWrapper, response.question.question, null, true);
        }
    }


}

function removeLocalStorageAnswerLog(questionId)
{
    if (questionId) {
        //remove previous question from local storage and existing one
        if (questionId === 'question_2') {
            localStorage.removeItem('question_3');
        } else {
            localStorage.removeItem('question_2');
        }
        localStorage.removeItem(questionId);

    } else {
        for (let i = 0; i <= 3; i++) {
            if (localStorage.getItem(`question_${i}`)) {
                localStorage.removeItem(`question_${i}`);
            }
        }
    }

}

function hasForwardSlashAtBeginningOfWord(str) {
    const regex = /\/\b/;
    return regex.test(str);
}

function printURL(url) {
    const printWindow = window.open(url, '_blank');
    printWindow.addEventListener('load', function() {
        printWindow.print();
    }, true);
}

function showLoading(wrapper, show = true) {
    const selfServeWidgetContainer = document.querySelector('.self-serve-widget__wrapper');
    const selfServeAssistant = selfServeWidgetContainer.querySelector('.self-serve-widget__assistant');
    const selfServeAssistantMessagesWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-wrapper');
    const selfServeAssistantAnswersWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-answers-wrapper');
    //create and add class to loading div
    if (show) {
        //update question and answers on frontend
        selfServeAssistantMessagesWrapper.innerHTML = '';
        selfServeAssistantAnswersWrapper.innerHTML = '';
        const loadingDiv = document.createElement('div');
        loadingDiv.classList.add('loading');
        wrapper.appendChild(loadingDiv);
    } else {
        //hide loading div
        const loadingElipse = document.querySelectorAll('.loading');
        loadingElipse.forEach((loadingElipse) => {
            loadingElipse.classList.add('fade-out');
        });
    }


}

/**
 * Get question and answers for self serve widget based on question id and previous answers if applicable
 *
 * @param question
 * @param nextOrPrevQuestion
 * @param currentAnswer
 * @returns {Promise<any>}
 */
function getSelfServeInfoForQuestion(question, nextOrPrevQuestion, currentAnswer = null) {
    switch('question_' + nextOrPrevQuestion) {
        case 'question_1':
           return getSelfServeInfo(nextOrPrevQuestion);
        case 'question_2':
            return getSelfServeInfo(nextOrPrevQuestion, currentAnswer.answer);
        case 'question_3':
            return getSelfServeInfo(nextOrPrevQuestion, currentAnswer.answer, currentAnswer.role);
    }
}

/**
 * Show and hide loading state
 *
 * @param action
 */
function showAndHideLoading(action = null, content = null, timeout = null) {
    const loadingWrapper = document.querySelector('.self-serve-widget__assistant__loader__wrapper');
    if (action === 'show'){
        loadingWrapper.classList.add('active');
    }else{
        if (timeout) {
            setTimeout((e) => {
                loadingWrapper.classList.remove('active');
            }, timeout);
        }else{
            loadingWrapper.classList.remove('active');
        }
    }
}

/**
 * Update the message/question or answers within the widget
 *
 * @param wrapper
 * @param message
 * @param timeout
 * @param append
 */
async function updateMessageOrAnswers(wrapper, message = null, timeout = null, append = false) {
    if (timeout) {
        const loadingDiv = document.createElement('div');
        loadingDiv.classList.add('loading');
        setTimeout(()=> {
                    //if message is not an array or object
                    if (append) {
                        //show loading g div
                        wrapper.appendChild(loadingDiv);
                        //
                        setTimeout((e)=> {
                            const loadingElipse = document.querySelectorAll('.loading');
                            loadingElipse.forEach((loadingElipse) => {
                                loadingElipse.classList.add('fade-out');
                            });
                            //check to see if we are appending a html element
                            if (message instanceof HTMLElement) {
                                wrapper.appendChild(message);
                            } else {
                                wrapper.innerHTML = wrapper.innerHTML + message;
                            }
                            attachAnswersEventListener();
                            onSelectAnswer()
                        }, timeout);
                    }else{
                        //overwrite entire html if not appending
                        wrapper.innerHTML = message;
                    }
            }
            ,timeout);
        addEventListenersToAnswerMessages();
    }else {
        //this will run when no timeout is specified
        //check if message is array or object
        if (message.constructor === Array || message.constructor === Object) {
            if (message.constructor === Object) {
                message = convertObjectToArray(message);
            }

            let i = 0;
            message.forEach(answer => {
                const answerHtml = answer.action ? `<button class="self-serve-widget__assistant__answer animate__animated animate__fadeInRight animate__delay-${i}s" data-value="${answer.value}"><a href="${answer.action}">${answer.value}</a></button>` : `<button class="self-serve-widget__assistant__answer animate__animated animate__fadeInRight animate__delay-${i}s" data-value="${answer}">${answer}</button>`;
                wrapper.innerHTML = wrapper.innerHTML + answerHtml;
                i++
            });
            attachAnswersEventListener();
            onSelectAnswer()
        } else {
            if (append) {
                wrapper.innerHTML = wrapper.innerHTML + message;
                attachAnswersEventListener();
                onSelectAnswer()

            } else {
                wrapper.innerHTML = message;
                attachAnswersEventListener();
            }
        }
        addEventListenersToAnswerMessages();
    }

}

function convertObjectToArray(object) {
    return Object.values(object);
}

/**
 * Reset widget to initial state
 *
 * @returns {Promise<void>}
 */
async function onReset() {
    removeTimeout();
    const selfServeWidgetContainer = document.querySelector('.self-serve-widget__wrapper');
    const selfServeAssistant = selfServeWidgetContainer.querySelector('.self-serve-widget__assistant');
    const selfServeAssistantMessagesWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-wrapper');
    const selfServeAssistantAnswersWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-answers-wrapper');
    const selfServeAssistantReset = selfServeAssistant.querySelector('.self-serve-widget__assistant-reset');
    const selfServeAssistantResultWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant__result-wrapper');
    const selfServeAssistantMessageAnswerWrapper = selfServeAssistant.querySelector('.self-serve-widget__assistant-messages-answers-wrapper');

    //clear local storage
    selfServeAssistantAnswersWrapper.innerHTML = '';
    selfServeAssistantMessagesWrapper.innerHTML = '';
    const response = await getSelfServeInfo(1);
    //update current question in local storage
    localStorage.setItem('current_question', JSON.stringify(response.question));
    //Hide back to start button
    selfServeAssistantReset.classList.add('d-none');
    //Show the messages-answer wrapper
    selfServeAssistantMessageAnswerWrapper.classList.add('active');
    //Hide result wrapper
    selfServeAssistantResultWrapper.classList.remove('active');
    //remove all questions from local storage
    removeLocalStorageAnswerLog();
    //reset both messages and answers wrappers html to question 1
    await updateMessageOrAnswers(selfServeAssistantMessagesWrapper, response.question.question, 800, true);
}

/**
 * Get the previous or next question
 *
 * @param questionId
 * @param action
 * @returns {number}
 */
function getNextOrPreviousQuestion(questionId, action) {
    let questionIdAsNumber = parseInt(questionId.split('_')[1]);
    if (action === 'previous') {
       return questionIdAsNumber - 1
    } else if(action === 'current') {
        return questionIdAsNumber;
    }
    return questionIdAsNumber + 1
}

function questionIdAsNumber(questionId)
{
   return parseInt(questionId.split('_')[1]);
}

function removeTimeout()
{
    if (localStorage.getItem('current_timeout')) {
        clearTimeout(localStorage.getItem('current_timeout'));
    }
}
